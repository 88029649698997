<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col class="text-center"  cols="4">
                <v-icon size="225" color="red">
                  fa-times
                </v-icon>
              </v-col>
              <v-col cols="5">
                <h1>
                  You are unable to access this resource.
                </h1>
              </v-col>
            </v-row>
            <v-row class="text-center" justify="center">
              <v-col cols="6">
                <h3>
                  You do not have the required role to access this resource.
                </h3>
                <v-btn class="mt-15 mb-15" color="primary" to="dashboard">Go Home</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Unauthorized'
}
</script>
